(function($) {
	//on load
	$(window).on('load', function () {
	
	});

	//on ready
	$(document).ready(function () {

		/* Modals */
		$('.getModal').on('click', function (e) {
			e.preventDefault();
			let target_modal = $(this).attr('href');
			if (!target_modal) {
				target_modal = $(this).data('modal');
			}
			$(target_modal).arcticmodal({
				beforeOpen: function () {
					$(target_modal).addClass('openEffect');
					$(target_modal).removeClass('closeEffect');
				},
				beforeClose: function () {
					$(target_modal).removeClass('openEffect');
					$(target_modal).addClass('closeEffect');
				}
			});
			return false;
		});


		/********************  forms  *********************/
		$('form').on('submit', function () {
			const form = $(this);
			const formFields = form.find('input, textarea').filter('[required]:visible');
			let formIsValid = true;
			formFields.css('outline', 'none'); //reset errors
			formFields.each(function () {
				if (($(this).val() === "") || (($(this).attr('name') === 'phone') && $(this).val().includes('_'))) {
					$(this).css('outline', '1px solid red');
					formIsValid = false;
				}
			});

			if (formIsValid) {
				const send_data = form.serialize(); // +"&send_to_email="+from_back.send_to_email;

				// $.ajax({
				// 	type: "POST",
				// 	url: 'send.php', // from_back.send_url
				// 	data: send_data,
				// 	success: function (data) {
				// 		$.arcticmodal('close');
				// 		$('#modal-success').arcticmodal();
				// 	},
				// 	error: function (xhr, str) {
				// 		alert("Error, try again!");
				// 	}
				// });
			}
			
			return false;
		});

		/* Toggle nav/cart */
		const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
		$('.toggle-btn').click(function () {
			let attr = $(this).attr('data-toggle');
			let findAttr = $('body').find(`[data-toggle=${attr}]`);

			if(findAttr.attr('data-click-state') == 1) {
				findAttr.attr('data-click-state', 0);
				$(`[data-toggle=${attr}]`).removeClass('active');
				$('body').removeClass('fixed').css('margin-right', `0px`);
			  }
			else {
				findAttr.attr('data-click-state', 1);
				$(`[data-toggle=${attr}]`).addClass('active');
				$('body').addClass('fixed').css('margin-right', `${scrollBarWidth}px`);
			}
		})

		/* Pack card */
		$(".pack-list .open-dropdown").click(function () {
			$(this).toggleClass("active").closest('.card-footer').siblings('.more-info').toggle();
		})

		const previewSlider = new Swiper('.preview-slider', {
			effect: 'fade',
			observer: true,
			observeParents: true,
			fadeEffect: {
				crossFade: true
			},
			navigation: {
			  nextEl: '.preview-slider-next',
			  prevEl: '.preview-slider-prev',
			},
		});

		const iconsSlider = new Swiper('.pack-list .icons-slider', {
			effect: 'fade',
			observer: true,
			observeParents: true,
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: '.swiper-pagination',
			},
		});

		const iconsModalSlider = new Swiper('.modal .icons-slider', {
			effect: 'fade',
			observer: true,
			observeParents: true,
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: '.swiper-pagination',
			},
			navigation: {
				nextEl: '.modal-preview-slider-next',
				prevEl: '.modal-preview-slider-prev',
			},
		});


		/* Before/After img */
		$(".img-box .slider").on("input change", (e)=>{
			const sliderPos = e.target.value;
			$('.foreground-img').css('width', `${sliderPos}%`)
			$('.slider-button').css('left', `calc(${sliderPos}% - 18px)`)
		});
		$('.ba-slider').beforeAfter();


		/* Input animation */
		$(".input-group input").focusout(function () {
			$(this).parent().addClass('active')
		})

		$( ".input-group input" )
			.focus(function () {
				$(this).parent().addClass('active').removeClass('error')
			})
			.blur(function () {
				$(this).parent().removeClass('active')
			});

		
		/* Select2 */

		$('.select2').select2({
			minimumResultsForSearch: -1,
			placeholder: "",
		});

		$('.select2').on('change', function(){
			$(this).siblings('.select2-container').addClass('selected');
		});

		$('.select2')
			.on('select2:opening', function () {
				$(this).parent().addClass('active')
			})
			.on('select2:close', function () {
				$(this).parent().removeClass('active')
			})
			.on('select2:select', function () {
				$(this).parent().addClass('selected')
			});

		
		/*** Tabs ***/
		$(".wallpapers-page .device.main-tabs input").on('change', function () {
			$(".wallpapers-page").find(`.device input[value=${$(this).val()}]`).click()
			$(".device-icons .icon").removeClass('active');
			$(this).closest('.device.main-tabs').siblings(".device-icons").children(`.${$(this).val()}`).addClass("active");
			
			if ( $(this).val() === 'desktop' ) {
				$(this).closest('.device.main-tabs').siblings(".hint.main-tabs").removeClass('hidden');
			} else {
				$(this).closest('.device.main-tabs').siblings(".hint.main-tabs").addClass('hidden');
			}
		})

		$(".wallpapers-page .device.card-tabs input").change(function () {
			$(this).closest('.card').siblings('.img-tabs').children().removeClass("active")
			$(this).closest('.card').siblings('.img-tabs').find(`.${$(this).val()}`).addClass('active');
			$(this).closest('.device.card-tabs').siblings('.card-row').find('input').attr('checked', false);

			if ( $(this).val() !== 'desktop' ) {
				$(this).closest('.card').find('.card-row').addClass('enabled');
			} else {
				$(this).closest('.card').find('.card-row').removeClass('enabled')
			}

			if ( $(this).val() === 'mobile' ) {
				$(this).closest('.card').siblings('.img-tabs').addClass('mobile');
			} else {
				$(this).closest('.card').siblings('.img-tabs').removeClass('mobile');
			}
		})


		/* Animations */
		const width = window.innerWidth;
		const controller = new ScrollMagic.Controller();
		const teaserAnimation = new TimelineMax()
		const ellipseAnimation = new TimelineMax()

		if (width >= 768) {
			if ($(".hero-section")[0]) {
				ellipseAnimation.to(".ellipse-box .ellipse-1", 4, { yPercent: 250 })
				ellipseAnimation.to(".ellipse-box .ellipse-2", 4, { yPercent: 180 }, "-=4")
				ellipseAnimation.to(".ellipse-box .ellipse-3", 4, { yPercent: 60 }, "-=4")
				ellipseAnimation.to(".ellipse-box .ellipse-4", 4, { yPercent: 40, xPercent: 40 }, "-=4")
				ellipseAnimation.to(".ellipse-box .ellipse-5", 4, { yPercent: 20 }, "-=4")
				ellipseAnimation.to(".ellipse-box .ellipse-5", 4, { yPercent: 20 }, "-=4")
			}

			if ($(".teaser")[0])  {
				ellipseAnimation.to(".hero-section .img-box .icons-group1", 4, { yPercent: 80 }, "-=4")
				ellipseAnimation.to(".hero-section .img-box .icons-group2", 4, { yPercent: 140 }, "-=4")
			}

			if ($(".hero-section .icons-group1, .hero-section .icons-group2")[0]) {
				teaserAnimation.to(".teaser .gradient", 4, { css: { background: 'rgba(0, 0, 0 ,0)' } })
				teaserAnimation.to(".teaser .teaser-title", 1, { opacity: 0, yPercent: -100 }, "-=4");
			}
				
		} else {

			if ($(".ellipse-box")[0]) {
				ellipseAnimation.to(".ellipse-box .ellipse-2", 4, { yPercent: 150 }, "-=4")
				ellipseAnimation.to(".ellipse-box .ellipse-5", 4, { yPercent: 80 }, "-=4")
			}

			if ($(".teaser")[0]) {
				teaserAnimation.to(".teaser .gradient", 4, { css: { background: 'rgba(0, 0, 0 ,0)' } })
				teaserAnimation.to(".teaser .teaser-title", 1, { opacity: 0, yPercent: -80 }, "-=4");
			}
		}
		
		if ($(".hero-section")[0]) {
			const heroScene = new ScrollMagic.Scene({
				triggerElement: ".hero-section", 
				duration: "100%",
				triggerHook: 0,
			})
			.setTween(ellipseAnimation)
			// .addIndicators() 
			.addTo(controller);
		}

	
		if ($(".teaser")[0]) {
			const teaserScene = new ScrollMagic.Scene({
				triggerElement: ".teaser", 
				duration: "100%",
				triggerHook: 0,
			})
			.setTween(teaserAnimation)
			// .addIndicators() 
			.addTo(controller);
		}	
		
		
		
	}); //END on ready

})(jQuery);